@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Primary (Elm) */
    --primary-300: 189 27% 24%; /* #2d494e */
    --primary-400: 184 61% 14%; /* #0e3639 */
    --primary-500: 186.98 100% 8.43%; /* #00262b */
    --primary-700: 180, 100%, 6%; /* #002121 */
    --primary: var(--primary-500);
    --primary-light: var(--primary-300);
    --primary-foreground: var(--white);
    --primary-hover: var(--white);
    --primary-hover-foreground: var(--primary);

    /* Secondary (Fern) */
    --secondary-300: 169 9% 52%; /* #798f8b */
    --secondary-400: 168 16% 36%; /* #4c6a64 */
    --secondary-500: 167.37 38% 19.61%; /* #1f453d */
    --secondary-700: 169 37% 12%; /* #132925 */
    --secondary: var(--secondary-500);
    --secondary-light: var(--secondary-300);
    --secondary-dark: var(--secondary-700);
    --secondary-foreground: var(--white);
    --secondary-hover: var(--white);
    --secondary-hover-foreground: var(--primary);

    /* Brand (Orange) */
    --brand-300: 17 78% 58%; /* e76f3f */
    --brand-500: 18 100% 42%; /* #d74000 */
    --brand-700: 17 91% 34%; /* #a33508 */
    --brand: var(--brand-500);
    --brand-light: var(--brand-300);
    --brand-dark: var(--brand-700);
    --brand-foreground: var(--white);
    --brand-hover: var(--white);
    --brand-hover-foreground: var(--primary);

    /* Accent */
    --accent-mint: 164 71% 63%; /* #5de3c0 */
    --accent-pink: 341 63% 79%; /* #eba7bc */
    --accent-blue: 189 97% 46%; /* #03c7e8 */
    --accent-yellow: 51 100% 47%; /* #f0cc00 */
    --accent: var(--light);
    --accent-foreground: var(--primary);

    /* Putty */
    --putty-50: 20 10% 94%; /* #f2f0ef */
    --putty-100: 40 20% 97%; /* #f9f8f6 */
    --putty-200: 40 20% 94%; /* #f3f1ed */
    --putty-300: 47 20% 91%; /* #edebe4 */
    --putty-400: 45 20% 88%; /* #e7e4db */
    --putty-500: 45 21% 85%; /* #e1ddd1 */
    --putty-700: 47 4% 51%; /* #87857e */
    --putty: var(--putty-500);
    --putty-light: var(--putty-300);
    --putty-dark: var(--putty-700);
    --putty-foreground: var(--black);
    --putty-dark-foreground: var(--white);

    --light: var(--putty-100);
    --light-foreground: var(--primary);

    /* Gray */
    --gray-500: 0 0% 43.92%; /* #707070 */
    --gray-700: 0 0% 27.06%; /* #454545 */
    --gray-800: 0, 0%, 20%; /* #333333 */
    --gray-900: 210 11% 15%; /* #212529 */
    --gray: var(--gray-500);
    --gray-dark: var(--gray-700);
    --gray-foreground: var(--white);

    /* Danger/Destructive */
    --danger-100: 6 63% 97%; /* #fcf3f2 */
    --danger-300: 4 66% 75%; /* #e99994 */
    --danger-500: 4 68% 49%; /* #d23228 */
    --danger-700: 4 90% 30%; /* #921108 */
    --danger: var(--danger-500);
    --danger-light: var(--danger-300);
    --danger-dark: var(--danger-700);
    --danger-foreground: var(--white);
    --danger-light-foreground: var(--black);

    --destructive: var(--danger-dark);
    --destructive-foreground: var(--danger-foreground);

    /* Warning */
    --warning-100: 52 100% 93%; /* #fffadb */
    --warning-300: 51 100% 47%; /* #f0cc00 */
    --warning-500: 51 100% 42%; /* #d6b600 */
    --warning: var(--warning-300);
    --warning-light: var(--warning-100);
    --warning-dark: var(--warning-500);
    --warning-foreground: var(--black);

    /* Success */
    --success-100: 158 44% 96%; /* #f2faf7 */
    --success-300: 155 54% 41%; /* #30a171 */
    --success-500: 154 81% 27%; /* #0d7d4d */
    --success: var(--success-300);
    --success-light: var(--success-100);
    --success-dark: var(--success-500);
    --success-foreground: var(--white);
    --success-light-foreground: var(--black);

    /* Info */
    --info-100: 191 52% 96%; /* #eff8fa */
    --info-300: 198 74% 43%; /* #1c8dbe */
    --info-500: 196 100% 28%; /* #00688d */
    --info: var(--info-300);
    --info-light: var(--info-100);
    --info-dark: var(--info-500);
    --info-foreground: var(--white);
    --info-light-foreground: var(--black);
    --background: var(--white);
    --foreground: var(--primary);
    --white: 0 0% 100%; /* #fff */
    --black: 0, 0%, 0%; /* #000 */

    --popover: var(--white);
    --popover-foreground: var(--primary);

    --card: var(--white);
    --card-foreground: var(--primary);
    --card-gray: var(--putty-100);
    --card-gray-hover: var(--putty-400);

    --spotlight: var(--info-100);
    --spotlight-foreground: var(--link-hover);

    --border: var(--putty-light);
    --input: var(--putty-light);
    --ring: var(--primary);

    --radius: 6rem;

    --learn-hero-heading: 2.25rem;
    --learn-hero-line-height: 2.25rem;

    --learn-hero-line-height-large: 4rem;
    --learn-hero-heading-large: 4rem;

    --learn-course-hero-heading: 2.5rem;
    --learn-course-hero-line-height: 2.5rem;

    --link: var(--info-dark);
    --link-hover: 201.58 100% 22.35%; /* #004972 */

    --masters: var(--primary-400);
    --masters-foreground: var(--white);

    --block: var(--putty-100);
    --block-foreground: var(--primary);

    --shadow: var(--putty-100);
    --shadow-foreground: var(--primary);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground antialiased;
  }
  main {
    /* Do not use 'overflow-hidden' here. It will break the layout */
    @apply max-w-full overflow-x-clip;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply font-sans text-primary-500;
  }
  h2,
  h3,
  h4 {
    @apply font-bold;
  }
  h1 {
    @apply text-2xl my-6 pt-6 md:text-5xl font-extrabold mb-2;
  }
  h2 {
    @apply text-lg md:text-2xl my-8;
  }
  h3 {
    @apply text-xl my-6;
  }
  h4 {
    @apply text-lg my-6;
  }
  p {
    @apply text-base text-gray-dark font-sans mb-4;
  }
  .container ul:not(.list-none) {
    list-style-type: disc;
  }
  .container ul {
    @apply px-10;
  }
  .program h2 {
    @apply text-3xl;
  }
  .program .about {
    @apply text-5xl;
  }
  .container ol:not(.list-none) {
    list-style-type: decimal;
    @apply px-10;
  }
  sup > a {
    @apply text-link;
  }
  blockquote {
    display: block;
    position: relative !important;
    border: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 10px;
    margin-inline-end: 60px;
    padding-left: 40px !important;
  }
  blockquote::before {
    background-color: #d23228;
    content: "";
    height: 68px;
    left: 0;
    position: absolute;
    transform: skewX(-12deg);
    width: 4px;
  }
  .clip-slant-left {
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .clip-slant-x-both {
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  }

  /* styles that should not be purged by tailwind */
  .btn-primary {
    @apply bg-primary text-white py-2 px-4;
  }
  .btn-brand {
    @apply bg-brand text-white py-2 px-4;
  }
  .fullwidth {
    @apply mx-break-out py-12 md:py-16;
    > * {
      @apply container;
    }
  }
  .imageContainerWithSkewRight {
    transform: skew(-12deg) translateX(50px);
    overflow: hidden;
    height: 100%;
  }
  .subnav-item {
    scroll-margin-top: 6.625rem; /* height of sticky nav */
  }
  a.external::after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" role="img" focusable="false" aria-hidden="true"><path d="M19 19H5V5h7V3H3v18h18v-9h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" fill="currentColor"></path></svg>');
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    vertical-align: middle;
    line-height: normal;
  }
  .container table th,
  .container table td {
    border-color: #ccc !important;
    border: 1px solid #ccc !important;
    border-width: 1px;
    @apply p-4;
  }
  article.prose table tr th,
  article.prose table tr td {
    border: 1px solid #ccc !important;
    border-color: #ccc !important;
    border-width: 1px;
    @apply border-solid;
  }
  .dynamic-grid {
    @apply grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}
